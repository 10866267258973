// import { Button, TextField } from '@mui/material';
import React from 'react';
// import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

const BlogList = () => {
  // const {
  //   control,
  //   handleSubmit,
  //   formState: { errors }
  // } = useForm();

  // const onSubmit = (data) => {
  //   console.log(data);
  // };

  return (
    <>
      <div className="blog_area pt-85 pb-65">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single_blog mb-30">
                <div className="single_blog_thumb pb-4">
                  <Link to="/blog/cloud-service">
                    <img src="/images/blog-pic1.png" alt="" />
                  </Link>
                </div>
                <div className="single_blog_content pl-4 pr-4">
                  <div className="techno_blog_meta">
                    <Link to="/blog/cloud-service">Amizhth</Link>
                    <span className="meta-date pl-3">
                      March 24, 2024 | By Pradeepa
                    </span>
                  </div>
                  <div className="blog_page_title pb-35">
                    <h3>
                      <Link to="/blog/cloud-service">
                        Modernise Applications by Exploiting the Cloud <br />{' '}
                        Services.
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single_blog mb-30">
                <div className="single_blog_thumb pb-4">
                  <Link to="/blog/springboot-tomcat">
                    <img src="/images/blog-pic2.png" alt="" />
                  </Link>
                </div>
                <div className="single_blog_content pl-4 pr-4">
                  <div className="techno_blog_meta">
                    <Link to="/blog/springboot-tomcat">Amizhth</Link>
                    <span className="meta-date pl-3">
                      June 3, 2024 | By Antony Basco
                    </span>
                  </div>
                  <div className="blog_page_title pb-35">
                    <h3>
                      <Link to="/blog/springboot-tomcat">
                        Spring Boot Application on AWS EC2 using Apache Tomcat
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single_blog mb-30">
                <div className="single_blog_thumb pb-4">
                  <Link to="/blog/springboot-docker">
                    <img src="/images/blog-pic3.png" alt="" />
                  </Link>
                </div>
                <div className="single_blog_content pl-4 pr-4">
                  <div className="techno_blog_meta">
                    <Link to="/blog/springboot-docker">Amizhth</Link>
                    <span className="meta-date pl-3">
                      August 4, 2024 | By Antony Basco{' '}
                    </span>
                  </div>
                  <div className="blog_page_title pb-35">
                    <h3>
                      <Link to="/blog/springboot-docker">
                        Spring Boot Application as Docker Container Image on AWS
                        EC2
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="subscribe_area bg_color pt-30 pb-45">
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="single_subscribe_contact">
                <div className="subscribe_content_title white text_center pb-30">
                  <h2>Subscribe Our Newsletter</h2>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} action="#">
                  <div className="subscribe_form">
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: 'Please enter your email',
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: 'Please enter a valid email address'
                        }
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="email"
                          fullWidth
                          variant="outlined"
                          placeholder="Enter Your Email"
                          error={!!errors.email}
                          helperText={errors.email ? errors.email.message : ''}
                        />
                      )}
                    />
                  </div>
                  <center>
                    <div className="subscribe-form mt-30">
                      <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        sx={{
                          color: 'white',
                          borderColor: 'white',
                          '&:hover': {
                            borderColor: 'white',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)'
                          }
                        }}
                      >
                        Subscribe
                      </Button>
                    </div>
                  </center>
                </form>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default BlogList;
