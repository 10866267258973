import React from 'react';
const AboutBanner = () => {
  return (
    <>
      <div className="breatcome_area3 d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breatcome_title">
                {/* <div className="breatcome_title_inner pb-2">
                  <h2>About Us</h2>
                </div>
                <div className="breatcome_content">
                  <ul>
                    <li>
                      <a href="index.html">Home</a>
                      <i className="fa fa-angle-right"></i>{' '}
                      <a href="#"> Pages</a>
                      <i className="fa fa-angle-right"></i>
                      <span>About Us</span>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="why_choose_area pt-80 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="prfs_experience">
                <img src="/images/Team.png" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <p>
                  <b>Amizhth Techno Solutions,</b> established during the
                  COVID-19 pandemic in 2020, is built on the belief that
                  &ldquo;Victory comes from finding opportunities in
                  problems&rdquo;. In a short span of time, we have partnered
                  with multiple clients, helping them overcome business
                  challenges by offering comprehensive digital solutions under
                  one roof. This has made us a trusted, one-stop technology
                  partner for many. We are proud to have developed portals for
                  StartupTN and the Transport Commissionerate Office of the
                  Government of Tamil Nadu as part of our software development
                  services. These partnerships reflect our commitment to
                  delivering innovative solutions that drive progress.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <center>
          <h1>
            <b>We strongly uphold</b>
          </h1>
        </center>
      </div>

      <div className="flipbox_area pages mt-70 pb-70 two">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-6">
              <div className="techno_flipbox mb-30">
                <div className="techno_flipbox_font">
                  <div className="techno_flipbox_inner">
                    <div className="techno_flipbox_icon">
                      <center>
                        <img
                          src="/images/abt3.png"
                          className="img-fluid w-50"
                        />
                      </center>
                    </div>
                    <div className="flipbox_title">
                      <h3>Transparent Process</h3>
                    </div>
                  </div>
                </div>
                <div
                  className="techno_flipbox_back"
                  style={{ backgroundImage: 'url(/images/feature1.jpg)' }}
                >
                  <div className="techno_flipbox_inner">
                    <div className="flipbox_title">
                      <h3>
                        Foster transparent operations to quickly build client
                        trust and understanding.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-6">
              <div className="techno_flipbox mb-30">
                <div className="techno_flipbox_font">
                  <div className="techno_flipbox_inner">
                    <div className="techno_flipbox_icon">
                      <center>
                        <img
                          src="/images/abt4.png"
                          className="img-fluid w-50"
                        />
                      </center>
                    </div>
                    <div className="flipbox_title">
                      <h3>Innovative Solutions</h3>
                    </div>
                  </div>
                </div>
                <div
                  className="techno_flipbox_back"
                  style={{ backgroundImage: 'url(/images/feature2.jpg)' }}
                >
                  <div className="techno_flipbox_inner">
                    <div className="flipbox_title">
                      <h3>
                        Creative strategies delivering powerful and effective
                        results
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-6">
              <div className="techno_flipbox mb-30">
                <div className="techno_flipbox_font">
                  <div className="techno_flipbox_inner">
                    <div className="techno_flipbox_icon">
                      <center>
                        <img
                          src="/images/abt2.png"
                          className="img-fluid w-50"
                        />
                      </center>
                    </div>
                    <div className="flipbox_title">
                      <h3>Continuous Improvement</h3>
                    </div>
                  </div>
                </div>
                <div
                  className="techno_flipbox_back"
                  style={{ backgroundImage: 'url(/images/feature3.jpg)' }}
                >
                  <div className="techno_flipbox_inner">
                    <div className="flipbox_title">
                      <h3>Consistent progress for ongoing success.</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flipbox_area pages pb-70 two">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-6">
              <div className="techno_flipbox mb-30">
                <div className="techno_flipbox_font">
                  <div className="techno_flipbox_inner">
                    <div className="techno_flipbox_icon">
                      <center>
                        <img
                          src="/images/abt6.png"
                          className="img-fluid w-50"
                        />
                      </center>
                    </div>
                    <div className="flipbox_title">
                      <h3>Trust and Reliability</h3>
                    </div>
                  </div>
                </div>
                <div
                  className="techno_flipbox_back"
                  style={{ backgroundImage: 'url(/images/feature1.jpg)' }}
                >
                  <div className="techno_flipbox_inner">
                    <div className="flipbox_title">
                      <h3>
                        Consistent support builds strong, lasting partnerships.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-6">
              <div className="techno_flipbox mb-30">
                <div className="techno_flipbox_font">
                  <div className="techno_flipbox_inner">
                    <div className="techno_flipbox_icon">
                      <center>
                        <img
                          src="/images/abt5.png"
                          className="img-fluid w-50"
                        />
                      </center>
                    </div>
                    <div className="flipbox_title">
                      <h3>Clear Communication</h3>
                    </div>
                  </div>
                </div>
                <div
                  className="techno_flipbox_back"
                  style={{ backgroundImage: 'url(/images/feature2.jpg)' }}
                >
                  <div className="techno_flipbox_inner">
                    <div className="flipbox_title">
                      <h3>
                        Straightforward dialogue enhances collaboration and
                        understanding.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-6">
              <div className="techno_flipbox mb-30">
                <div className="techno_flipbox_font">
                  <div className="techno_flipbox_inner">
                    <div className="techno_flipbox_icon">
                      <center>
                        <img
                          src="/images/abt1.png"
                          className="img-fluid w-50"
                        />
                      </center>
                    </div>
                    <div className="flipbox_title">
                      <h3>Personalized Service</h3>
                    </div>
                  </div>
                </div>
                <div
                  className="techno_flipbox_back"
                  style={{ backgroundImage: 'url(/images/feature3.jpg)' }}
                >
                  <div className="techno_flipbox_inner">
                    <div className="flipbox_title">
                      <h3>
                        Tailored experiences meet unique client needs
                        effectively.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutBanner;
