import React from 'react';

const ChooseUs = () => {
  return (
    <>
      <div className="accordion-area abouts">
        <div className="container">
          <h1 style={{ textAlign: 'center' }}>From the CEO&apos;s Desk</h1>
          <h3 className="pb-50" style={{ textAlign: 'center' }}>
            A warm welcome to Amizhth Techno Solutions
          </h3>
          <div className="row">
            <div className="col-lg-6 p-0 main-accordion-lt">
              <div className="acd-items acd-arrow1 pt-30">
                <div className="section_title white text_left mt-3">
                  <div className="section_main_title">
                    <h1 style={{ textAlign: 'center' }}>
                      &quot;Amizhth Techno Solutions is
                    </h1>
                    <h1 style={{ textAlign: 'center' }}>
                      built around customer
                    </h1>
                    <h1 style={{ textAlign: 'center' }}>satisfaction.&quot;</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 absod">
              <div className="single-panel">
                <div className="single-panel-thumb">
                  <p style={{ marginLeft: '15px', textAlign: 'justify' }}>
                    At Amizhth, we believe that excellence comes from a deep
                    understanding of both technology and the unique needs of our
                    clients. As a team of passionate professionals, we are
                    committed to delivering solutions that not only meet today’s
                    challenges but also set the foundation for tomorrow’s
                    success. Our strength lies in our ability to listen, adapt,
                    and innovate—ensuring that every project is aligned with the
                    goals of our clients, whether it’s creating dynamic
                    websites, securing systems, or driving digital
                    transformation.
                  </p>
                  <p style={{ marginLeft: '15px', textAlign: 'justify' }}>
                    What sets us apart is our relentless pursuit of quality,
                    combined with a flexible approach tailored to each client.
                    We don’t just deliver software; we craft solutions that
                    evolve with your business. Our proven expertise in web
                    development, mobile applications, cybersecurity, and beyond,
                    paired with our customer-first mindset, ensures that your
                    success is at the heart of everything we do.
                  </p>
                  <p style={{ marginLeft: '15px', textAlign: 'justify' }}>
                    Together, we build not just products, but lasting
                    partnerships—because your growth is our ultimate measure of
                    success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="accordion-area abouts">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 absod">
              <div className="single-panel1">
                <div className="single-panel-thumb">
                  <p
                    style={{
                      marginLeft: '10px',
                      textAlign: 'justify',
                      marginRight: '15px'
                    }}
                  >
                    A visionary Tech Thamizhan and enterprising techpreneur,
                    passionately uniting the realms of Thamizh and Technology.
                    With an unwavering dedication to both innovation and the
                    preservation of cultural heritage, he is carving a path
                    where advanced technology harmoniously coexists with the
                    timeless essence of Thamizh. Strongly believing that true
                    success is measured by the collective progress of every
                    individual in society, he is relentlessly working toward
                    creating an ecosystem that fosters inclusive growth,
                    empowerment, and shared prosperity.
                  </p>
                  <h1 style={{ marginLeft: '10px' }}>Prabahar Murugan</h1>
                  <h3 style={{ marginLeft: '10px' }}>
                    Chief Executive Officer
                  </h3>
                  <div style={{ marginLeft: '10px' }} className="social-icons">
                    <a
                      href="https://www.facebook.com/praba.mur27"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/prabaharm/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/praba_thamizhan/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 main-accordion-lt">
              <div className="acd-items acd-arrow pt-30 pb-30">
                <div className="section_title white text_left mb-60 mt-3">
                  <div className="section_main_title">
                    <img src="/images/ceo.png" className="ceo-img img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseUs;
