import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

const Contact = () => {
  $(document).ready(function () {
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 100) {
        $('#backtotop').fadeIn();
      } else {
        $('#backtotop').fadeOut();
      }
    });
    $('#backtotop').click(function () {
      $('html, body').animate(
        {
          scrollTop: 0
        },
        600
      );
      return false;
    });
  });
  const scrollToTop = () => {
    $('html, body').animate(
      {
        scrollTop: 50
      },
      600
    );
  };
  return (
    <>
      <div className="call-do-action style-two pt-120 pb-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="call-do-action-content">
                <div className="call-do-thumb-box">
                  <div className="call-do-thumb4 rotateme">
                    <img src="/images/box-2.png" alt="" />
                  </div>
                </div>
              </div>
              <div
                className="section_title style-two mt-3 wow fadeInRight"
                data-wow-delay=".4"
              >
                <div className="section_sub_title">
                  <h5>Reach Out</h5>
                </div>
                <div className="section_main_title">
                  <h1>Sounds Like Amizhth Might</h1>
                  <h1>
                    Be The Right Choice For <br />
                    Your Business?
                  </h1>
                </div>
                <div className="call-do-icon">
                  <i className="fa fa-envelope"></i>
                  <span>vanakkam@amizhth.com</span>
                </div>
                <div className="call-button wow fadeInUp" data-wow-delay=".5">
                  <Link to="/contact" onClick={scrollToTop}>
                    {' '}
                    Contact Us <i className="bi bi-arrow-right"></i>{' '}
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 wow fadeInDown"
              data-wow-delay=".5"
            >
              <div className="call-do-main-thumb bounce-animate3 pl-70">
                <img src="/images/call-do-main.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
