import React from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';

const Navbar = () => {
  $(document).ready(function () {
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 100) {
        $('#backtotop').fadeIn();
      } else {
        $('#backtotop').fadeOut();
      }
    });
    $('#backtotop').click(function () {
      $('html, body').animate(
        {
          scrollTop: 0
        },
        600
      );
      return false;
    });
  });
  $('nav .navbar-collapse ul li').click(function () {
    $('.navbar-collapse').removeClass('show');
  });
  const scrollToTop = () => {
    $('html, body').animate(
      {
        scrollTop: 0
      },
      600
    );
  };

  return (
    <>
      {/* <div
        id="sticky-header"
        className="techno_nav_manu transparent_menu white d-md-none d-lg-block d-sm-none d-none style-two"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="menu">
              <Link to="/" className="logo">
                <img
                  className="down img-fluid w-25"
                  src="/images/logo.png"
                  alt=""
                />
                <img className="main_sticky" src="/images/logo.png" alt="" />
              </Link>
              <ul className="clearfix">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/service">Services</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-menu-area d-sm-block d-md-block d-lg-none">
        <div className="mobile-menu">
          <nav className="techno_menu">
            <ul className="clearfix">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/service">Services</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div> */}

      {/* <nav className="navbar navbar-expand-lg navbar-light bg-light p-3">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src="/images/logo.png"
              alt="Logo"
              className="img-fluid"
              style={{ width: '100px' }}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  activeClassName="active"
                  exact
                  onClick={scrollToTop}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/about"
                  activeClassName="active"
                  onClick={scrollToTop}
                >
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/service"
                  activeClassName="active"
                  onClick={scrollToTop}
                >
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/blog"
                  activeClassName="active"
                  onClick={scrollToTop}
                >
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/contact"
                  activeClassName="active"
                  onClick={scrollToTop}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}

      <nav className="navbar navbar-expand-lg navbar-light bg-light p-3">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img
              src="/images/logo.png"
              alt="Logo"
              className="img-fluid"
              style={{ width: '100px' }}
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink
                  exact
                  className="nav-link"
                  to="/"
                  activeClassName="active"
                  onClick={scrollToTop}
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/about"
                  activeClassName="active"
                  onClick={scrollToTop}
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/service"
                  activeClassName="active"
                  onClick={scrollToTop}
                >
                  Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/blog"
                  activeClassName="active"
                  onClick={scrollToTop}
                >
                  Blog
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/contact"
                  activeClassName="active"
                  onClick={scrollToTop}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
