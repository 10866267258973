import { Box, Button, Grid, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Testimonial = () => {
  return (
    <>
      <Box sx={{ backgroundColor: '#333', padding: '4rem 2rem' }}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle2"
              sx={{ color: '#ffffff', letterSpacing: 2 }}
            >
              LET&apos;S COLLABORATE
            </Typography>
            <Typography
              variant="h4"
              sx={{ color: '#ffffff', marginTop: '1rem' }}
            >
              Send us an email, to discuss a new project.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'start', md: 'end' },
              marginTop: { xs: '2rem', md: '0' }
            }}
          >
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              sx={{
                backgroundColor: '#ffffff',
                color: '#000000',
                padding: '1rem 2rem',
                fontSize: '1rem',
                fontWeight: 'bold',
                borderRadius: '0.5rem',
                '&:hover': {
                  backgroundColor: '#e0e0e0'
                }
              }}
            >
              <a href="/contact">Contact Us</a>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Testimonial;
