import React from 'react';
const CloudserviceBanner = () => {
  return (
    <>
      <div className="breatcome_area5 d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breatcome_title">
                {/* <div className="breatcome_title_inner pb-2">
                  <h2>Blog Details</h2>
                </div>
                <div className="breatcome_content">
                  <ul>
                    <li>
                      <a href="index.html">Home</a>
                      <i className="fa fa-angle-right"></i> <a href="#"> Pages</a>
                      <i className="fa fa-angle-right"></i> <span>Blog Gird</span>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CloudserviceBanner;
