import { Grid } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin } from 'react-icons/fa6';

const TeamMember = () => {
  const handlelinkedin = (value) => {
    window.open(value, '_blank', 'noopener,noreferrer');
    // eslint-disable-next-line no-debugger
    debugger;
  };
  return (
    <>
      <div className="team_area pt-85 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="section_title text_left mb-60 mt-3">
                <div className="section_sub_title uppercase mb-3">
                  {/* <h6>TEAM MEMBER</h6> */}
                </div>
                <div className="section_main_title">
                  <h1>Our Awesome Creative</h1>
                  <h1>Squad</h1>
                </div>
                <div className="em_bar">
                  <div className="em_bar_bg"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="section_button mt-50">
                <div className="button two">
                  <Link to="/contact">Join Our Team</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="team_style_three mb-30">
                <div className="team_style_three_inner">
                  <div className="team_style_three-thumb">
                    <div className="team_style_three_thumb_inner">
                      <img src="/images/anto.png" alt="" />
                      <div className="overlay">
                        <p style={{ fontSize: '11px' }} className="px-2">
                          A Senior Technology Consultant with over 10 years of
                          experience, dedicated to deeply understanding client
                          needs and delivering high-quality, precision-driven
                          solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="team_style_three_content c-pointer">
                    <div className="team_style_three_title">
                      <h4>
                        Antony Basco
                        {/* <a
                          href="https://www.linkedin.com/in/antonybasco/"
                          className="c-pointer"
                          target="blank"
                        >
                          <i className="bi bi-linkedin"></i>
                        </a> */}
                        <span className="ml-3">
                          <FaLinkedin
                            onClick={() =>
                              handlelinkedin(
                                'https://www.linkedin.com/in/antonybasco/'
                              )
                            }
                            className="c-pointer"
                          />
                        </span>
                      </h4>
                    </div>
                    <div className="team_style_three_sub_title">
                      <span>Sr. Technology Consultant</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="team_style_three mb-30">
                <div className="team_style_three_inner">
                  <div className="team_style_three-thumb">
                    <div className="team_style_three_thumb_inner">
                      <img src="/images/subash.png" alt="" />
                      <div className="overlay">
                        <p style={{ fontSize: '11px' }} className="px-2">
                          A Senior Technology Specialist with over 4 years of
                          experience, dedicated to upholding coding standards
                          and ensuring timely project completion for maximum
                          impact.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="team_style_three_content">
                    <div className="team_style_three_title">
                      <h4>
                        Subash Hagin
                        <span className="ml-3">
                          <FaLinkedin
                            onClick={() =>
                              handlelinkedin(
                                'https://www.linkedin.com/in/subash-k-8506b6171/'
                              )
                            }
                            className="c-pointer"
                          />
                        </span>
                      </h4>
                    </div>
                    <div className="team_style_three_sub_title">
                      <span>Sr. Technology Specialist</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="team_style_three mb-30">
                <div className="team_style_three_inner">
                  <div className="team_style_three-thumb">
                    <div className="team_style_three_thumb_inner">
                      <img src="/images/subash-qa.png" alt="" />
                      <div className="overlay">
                        <p style={{ fontSize: '12px' }} className="px-2">
                          A Senior QA Specialist with over 10 years of
                          experience, focused on rigorous attention to detail,
                          test automation, and maintaining the highest quality
                          standards.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="team_style_three_content">
                    <div className="team_style_three_title">
                      <h4>
                        Subash G
                        <span className="ml-5">
                          <FaLinkedin
                            onClick={() =>
                              handlelinkedin(
                                'https://www.linkedin.com/in/subash-govindasamy-9b9b4a86/'
                              )
                            }
                            className="c-pointer"
                          />
                        </span>
                      </h4>
                    </div>
                    <div className="team_style_three_sub_title">
                      <span>Sr. QA Specialist</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="team_style_three mb-30">
                <div className="team_style_three_inner">
                  <div className="team_style_three-thumb">
                    <div className="team_style_three_thumb_inner">
                      <img src="/images/vignesh-designer.png" alt="" />
                      <div className="overlay">
                        <p style={{ fontSize: '12px' }} className="px-2">
                          A Creative Strategist with over 5 years of experience
                          at Amizhth Design Studio, dedicated to aesthetics and
                          impactful, results-oriented campaigns through elegant
                          design solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="team_style_three_content">
                    <div className="team_style_three_title">
                      <h4>
                        Vignesh A
                        <span className="ml-5">
                          <FaLinkedin
                            onClick={() =>
                              handlelinkedin(
                                'https://www.linkedin.com/in/vigneshwaranashokkumar/'
                              )
                            }
                            className="c-pointer"
                          />
                        </span>
                      </h4>
                    </div>
                    <div className="team_style_three_sub_title">
                      <span>Creative Strategist</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-60">
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="team_style_three mb-30">
                <div className="team_style_three_inner">
                  <div className="team_style_three-thumb">
                    <div className="team_style_three_thumb_inner">
                      <img src="/images/Priyanka.png" alt="" />
                      <div className="overlay">
                        <p style={{ fontSize: '11px' }} className="px-2">
                          A Project Specialist with over 10 years of experience,
                          dedicated to ensuring timely project completion and
                          delivering on client commitments with precision.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="team_style_three_content">
                    <div className="team_style_three_title">
                      <h4>
                        Priyanka
                        <span className="ml-5">
                          <FaLinkedin
                            onClick={() =>
                              handlelinkedin(
                                'https://in.linkedin.com/in/priya-bala-815884194/'
                              )
                            }
                            className="c-pointer"
                          />
                        </span>
                      </h4>
                    </div>
                    <div className="team_style_three_sub_title">
                      <span>Project Specialist</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div className="team_style_three mb-30">
                <div className="team_style_three_inner">
                  <div className="team_style_three-thumb">
                    <div className="team_style_three_thumb_inner">
                      <img src="/images/qa-vignesh.png" alt="" />
                      <div className="overlay">
                        <p style={{ fontSize: '11px' }} className="px-2">
                          A QA Specialist with 4+ years of experience, focused
                          on deliverable quality, UI aesthetics, performance,
                          and a keen attention to detail.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="team_style_three_content">
                    <div className="team_style_three_title">
                      <h4>
                        Vignesh M
                        <span className="ml-5">
                          <FaLinkedin
                            onClick={() =>
                              handlelinkedin(
                                'https://www.linkedin.com/in/vigneshmuruga/'
                              )
                            }
                            className="c-pointer"
                          />
                        </span>
                      </h4>
                    </div>
                    <div className="team_style_three_sub_title">
                      <span>QA Specialist</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-70">
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <div className="image-wrapper">
                <img
                  src="/images/gowshik.png"
                  className="img-fluid"
                  alt="Gowshik"
                />
                <div className="overlay1">
                  <div className="name">Gowshik</div>
                  <div className="role">Technology Specialist</div>
                  <span>
                    <FaLinkedin
                      style={{ fontSize: ' 22px' }}
                      onClick={() =>
                        handlelinkedin(
                          'https://www.linkedin.com/in/gowsik-m-52aa33245/'
                        )
                      }
                      className="c-pointer"
                    />
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <div className="image-wrapper">
                <img src="/images/aj.png" className="img-fluid" alt="AJ" />
                <div className="overlay1">
                  <div className="name">Ajay</div>
                  <div className="role">Technology Specialist</div>
                  <span>
                    <FaLinkedin
                      style={{ fontSize: ' 22px' }}
                      onClick={() =>
                        handlelinkedin(
                          'https://www.linkedin.com/in/deo-ajay-39a865210/'
                        )
                      }
                      className="c-pointer"
                    />
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <div className="image-wrapper">
                <img src="/images/k7.png" className="img-fluid" alt="K7" />
                <div className="overlay1">
                  <div className="name">Kesavan</div>
                  <div className="role">Technology Specialist</div>
                  <span>
                    <FaLinkedin
                      style={{ fontSize: ' 22px' }}
                      onClick={() =>
                        handlelinkedin(
                          'https://www.linkedin.com/in/kesavan-b-92a034230/'
                        )
                      }
                      className="c-pointer"
                    />
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <div className="image-wrapper">
                <img
                  src="/images/surya.png"
                  className="img-fluid"
                  alt="Surya"
                />
                <div className="overlay1">
                  <div className="name">Surya</div>
                  <div className="role">Technology Specialist</div>
                  <span>
                    <FaLinkedin
                      style={{ fontSize: ' 22px' }}
                      onClick={() =>
                        handlelinkedin(
                          'https://www.linkedin.com/in/surya-arun-a11277272/'
                        )
                      }
                      className="c-pointer"
                    />
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className="mt-70"
          >
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <div className="image-wrapper">
                <img
                  src="/images/ranjith.png"
                  className="img-fluid"
                  alt="Ranjith"
                />
                <div className="overlay1">
                  <div className="name">Ranjith</div>
                  <div className="role">Jr. Technology Specialist</div>
                  <span>
                    <FaLinkedin
                      style={{ fontSize: ' 22px' }}
                      onClick={() =>
                        handlelinkedin(
                          'https://www.linkedin.com/in/ranjith-kumar-5818a1281/'
                        )
                      }
                      className="c-pointer"
                    />
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <div className="image-wrapper">
                <img
                  src="/images/sneha.png"
                  className="img-fluid"
                  alt="Sneha"
                />
                <div className="overlay1">
                  <div className="name">Snegha</div>
                  <div className="role">Jr. Technology Specialist</div>
                  <span>
                    <FaLinkedin
                      style={{ fontSize: ' 22px' }}
                      onClick={() =>
                        handlelinkedin(
                          'https://www.linkedin.com/in/b-snegha-priya-537aaa223/'
                        )
                      }
                      className="c-pointer"
                    />
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <div className="image-wrapper">
                <img
                  src="/images/agalya.png"
                  className="img-fluid"
                  alt="Agalya"
                />
                <div className="overlay1">
                  <div className="name">Agalya</div>
                  <div className="role">Jr. Technology Specialist</div>
                  <span>
                    <FaLinkedin
                      style={{ fontSize: ' 22px' }}
                      onClick={() =>
                        handlelinkedin(
                          'https://www.linkedin.com/in/agalya-c-b26a67221/'
                        )
                      }
                      className="c-pointer"
                    />
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <div className="image-wrapper">
                <img
                  src="/images/ranjith-d.png"
                  className="img-fluid"
                  alt="Ranjith D"
                />
                <div className="overlay1">
                  <div className="name">Ranjith</div>
                  <div className="role">Marketing Design Specialist</div>
                  <span>
                    <FaLinkedin
                      style={{ fontSize: ' 22px' }}
                      onClick={() =>
                        handlelinkedin(
                          'https://www.linkedin.com/in/ranjithk2102/'
                        )
                      }
                      className="c-pointer"
                    />
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className="mt-70"
          >
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <div className="image-wrapper">
                <img
                  src="/images/rohit.png"
                  className="img-fluid"
                  alt="Rohit"
                />
                <div className="overlay1">
                  <div className="name">Rohith</div>
                  <div className="role">Jr. Technology Specialist</div>
                  <span>
                    <FaLinkedin
                      style={{ fontSize: ' 22px' }}
                      onClick={() =>
                        handlelinkedin(
                          'https://www.linkedin.com/in/rohith-s-59ba3016b/'
                        )
                      }
                      className="c-pointer"
                    />
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default TeamMember;
