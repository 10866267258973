import React from 'react';
import { Link } from 'react-router-dom';

const Banner = () => {
  return (
    <>
      <div className="slider_area d-flex align-items-center slider18" id="home">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-6 col-lg-6">
              <div className="single_slider wow fadeInUp" data-wow-delay=".4">
                <div className="slider_content">
                  <div className="slider_text">
                    <div className="slider_text_inner">
                      <h5></h5>
                      <h1 className="pt-40">Let`s Build Your</h1>
                      <h1>Digital Footprints!</h1>
                      <p>Secure, Scale, Succeed</p>
                    </div>
                    <div className="slider_button pt-3 d-flex">
                      <div className="button">
                        <Link to="/contact">
                          Let&apos;s Talk <i className="bi bi-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                    {/* <div className="hero-call-icon">
                      <div className="call-icon">
                        <i className="bi bi-telephone-plus"></i>
                      </div>
                      <div className="call-title">
                        <p>Call for Help</p>
                        <h6>+98 321 (7690) 326</h6>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="em-hero-inner-shape rotateme">
                  <img src="/images/box.png" alt="" />
                </div>
                <div className="em-hero-inner-shape1">
                  <img src="/images/line.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div
                className="hero_section_thumb wow fadeInDown"
                data-wow-delay=".5"
              >
                <div className="hero-main-thumb">
                  <img src="/images/main-img.png" alt="" />
                </div>
                <div className="em-hero-icon-thumb bounce-animate4">
                  <img src="/images/shape-img-1.png" alt="" />
                </div>
                <div className="em-hero-inner-thumb2 bounce-animate">
                  <img src="/images/roket.png" alt="" />
                </div>
                <div className="em-hero-inner-thumb4 bounce-animate5">
                  <img src="/images/icon.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img src="/images/mountain-2.png" className="mountain-img img-fluid" />
    </>
  );
};

export default Banner;
