import { Grid } from '@mui/material';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import axios from 'axios';

const Contactus = () => {
  const { handleSubmit, control, reset } = useForm();

  const onSubmit = (data) => {
    const config = {
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    };
    var req = {
      Name: data.name,
      Email: data.email,
      MobileNumber: data.phoneNumber,
      Message: data.message
    };
    return axios
      .post('https://formspree.io/f/mgveaeey', req, config)
      .then((result) => {
        if (result.data) {
          reset();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <section>
        <div className="container">
          <div className="gettouch">
            <h1 className="text-center">Get In Touch With us</h1>
          </div>
        </div>

        <div className="container">
          <Grid
            container
            spacing={3}
            direction="row"
            sx={{
              justifyContent: `'center'`,
              alignItems: `'center'`
            }}
          >
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="madurai-map">
                <h3>Madurai</h3>
                <iframe
                  style={{ border: '0px' }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3930.1074881147474!2d78.11755497389686!3d9.925005474323028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00c5bf4f1cac75%3A0x953cb62b8bb1c406!2sAmizhth%20Techno%20Solutions!5e0!3m2!1sen!2sin!4v1729854405704!5m2!1sen!2sin"
                  width="600"
                  height="250"
                  // style="border:0;"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                />
              </div>
              <div className="chennai-map">
                <h3>Chennai</h3>
                <iframe
                  style={{ border: '0px' }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.984279465964!2d80.19507927394123!3d12.908731816255827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525de003270d51%3A0x8d529ff74a16917e!2sAmizhth%20Techno%20Solutions!5e0!3m2!1sen!2sin!4v1729854535967!5m2!1sen!2sin"
                  width="600"
                  height="250"
                  // style="border:0;"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                />
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Name is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Name *"
                        fullWidth
                        margin="normal"
                        error={!!error}
                        helperText={error ? error.message : ''}
                      />
                    )}
                  />
                </div>

                <div>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Email is required',
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: 'Enter a valid email address'
                      }
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Email *"
                        fullWidth
                        margin="normal"
                        error={!!error}
                        helperText={error ? error.message : ''}
                      />
                    )}
                  />
                </div>

                <div>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Phone number is required',
                      maxLength: {
                        value: 10,
                        message: 'Phone number must be 10 digits'
                      },
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: 'Phone number must be exactly 10 digits'
                      }
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Phone Number *"
                        fullWidth
                        margin="normal"
                        error={!!error}
                        helperText={error ? error.message : ''}
                      />
                    )}
                  />
                </div>

                <div>
                  <Controller
                    name="message"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Message is required' }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Message *"
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                        error={!!error}
                        helperText={error ? error.message : ''}
                      />
                    )}
                  />
                </div>
                <center>
                  <button type="submit" className="con-submit-btn mb-30">
                    Submit
                  </button>
                </center>
              </form>
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
};

export default Contactus;
