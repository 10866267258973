import React from 'react';
import { Link } from 'react-router-dom';

const Service = () => {
  const features = [
    {
      title: 'App Development',
      description:
        'We are a well-recognized mobile app development company known for the timely delivery of future-ready mobile applications with exceptional quality. ',
      image: '/images/ser1.png'
    },
    {
      title: 'Web Development',
      description:
        'As a leading web development service provider we are ready to develop customized websites in a way to boost your digital identity.',
      image: '/images/ser2.png'
    },
    {
      title: 'IT Outsourcing',
      description:
        'We offer customized solutions designed to foster innovation, optimize your workflows, and ensure your IT environment is secure.',
      image: '/images/ser6.png'
    },
    {
      title: 'Cyber Security',
      description:
        'We offer premium Virtual CISO services, and robust risk management solutions designed to protect businesses of all sizes.',
      image: '/images/ser4.png'
    },
    {
      title: 'Digital Marketing',
      description:
        'We help you to drive high traffic, retain customers, and generate leads for your business, thus strengthening your reputation.',
      image: '/images/ser8.png'
    },
    {
      title: 'UI/UX Design',
      description:
        'We put our creative skills and technical advancement to create perfect UI/UX design to provide a hassle-free user experience.',
      image: '/images/ser3.png'
    },
    {
      title: 'Cloud Services',
      description:
        'Our team of experts collaborates with you to understand your unique needs and craft a tailored solution that fits both your goals and budget.',
      image: '/images/ser5.png'
    },
    {
      title: 'Data Analytics',
      description:
        'As a leading data analytics service, we empower businesses in various industries meet their challenging needs at enterprise levels.',
      image: '/images/ser7.png'
    }
  ];

  return (
    <>
      <div
        className="tree-bg feature-area owl-nav3 style-two"
        // style={{
        //   backgroundImage: "url('/images/Tree.png')",
        //   backgroundSize: 'cover',
        //   backgroundPosition: 'center',
        //   backgroundRepeat: 'no-repeat',
        //   position: 'relative',
        //   top: '-200px'
        // }}
      >
        <div className="container">
          <div className="row feature-shape">
            <div className="col-lg-8">
              <div
                className="section_title style-two mb-30 pb-1 mt-3 wow fadeInDown animated"
                data-wow-delay=".4"
                style={{ visibility: 'visible', animationName: 'fadeInLeft' }}
              >
                <div className="section_sub_title">
                  <h5>Services</h5>
                </div>
                <div className="section_main_title pb-15">
                  <h1>Crafting Digital Excellence</h1>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="em-feature-button text-right">
                <Link to="/service">
                  All Services <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="feature-shape1">
              <img src="images/border-f.png" alt="" />
            </div>
            <div className="feature-shape2 bounce-animate3">
              <img src="images/service-sp.png" alt="" />
            </div>
          </div>

          {/* <div className="row">
            <div className="team-list owl-carousel owl-loaded owl-drag">
              <div className="owl-stage-outer">
                <div
                  className="owl-stage"
                  style={{
                    transform: 'translate3d(-2280px, 0px, 0px)',
                    transition: '0.25s',
                    width: '3705px'
                  }}
                >
                  <div className="owl-item cloned" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner2">
                            <div className="feature-top-icon up">
                              <img
                                src="/images/ser1.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h3>App Development</h3>
                              <p>
                                We create tailored, user friendly mobile,
                                high-performance web and mobile applications for
                                your business
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="owl-item cloned" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser2.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>Web Development</h2>
                              <p>
                                We design and build responsive, & dynamic
                                websites to elevate your online presence driving
                                business growth
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="owl-item cloned" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner2">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser3.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>Ui/Ux Design</h2>
                              <p>
                                We create intuitive and engaging user interfaces
                                that enhance user experience and foster customer
                                retention
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="owl-item cloned" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser8.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>Digital Marketing</h2>
                              <p>
                                We develop tailored marketijng strategies to
                                enhance your brand visibility and engage your
                                target audience
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="owl-item" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner2">
                            <div className="feature-top-icon up">
                              <img
                                src="/images/ser1.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h3>App Development</h3>
                              <p>
                                We create tailored, user friendly mobile,
                                high-performance web and mobile applications for
                                your business
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="owl-item" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser2.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>Web Development</h2>
                              <p>
                                We design and build responsive, & dynamic
                                websites to elevate your online presence driving
                                business growth
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="owl-item" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner2">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser3.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>Ui/Ux Design</h2>
                              <p>
                                We create intuitive and engaging user interfaces
                                that enhance user experience and foster customer
                                retention
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="owl-item active" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser2.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>Web Development</h2>
                              <p>
                                We design and build responsive, & dynamic
                                websites to elevate your online presence driving
                                business growth
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="owl-item cloned active"
                    style={{ width: '285px' }}
                  >
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser4.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>Cyber Security</h2>
                              <p>
                                We provide comprehesive cyber security solutions
                                to protect your digital assets from threats and
                                ensure data integrity
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="owl-item cloned active"
                    style={{ width: '285px' }}
                  >
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner2">
                            <div className="feature-top-icon up">
                              <img
                                src="/images/ser1.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>App Development</h2>
                              <p>
                                We create tailored, user friendly mobile,
                                high-performance web and mobile applications for
                                your business
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="owl-item cloned active"
                    style={{ width: '285px' }}
                  >
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser2.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>Web Development</h2>
                              <p>
                                We design and build responsive, & dynamic
                                websites to elevate your online presence driving
                                business growth
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="owl-item cloned" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner2">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser3.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>Ui/Ux Design</h2>
                              <p>
                                We create intuitive and engaging user interfaces
                                that enhance user experience and foster customer
                                retention
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="team-list owl-carousel owl-loaded owl-drag">
              <div className="owl-stage-outer">
                <div
                  className="owl-stage"
                  style={{
                    transform: 'translate3d(-2280px, 0px, 0px)',
                    transition: '0.25s',
                    width: '3705px'
                  }}
                >
                  <div className="owl-item cloned" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner2">
                            <div className="feature-top-icon up">
                              <img
                                src="/images/ser5.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>IT Consulting</h2>
                              <p>
                                We offer IT consulting services to optimize
                                technology strategy & drive effective digital
                                transformation{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="owl-item cloned" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser6.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>IT Outsourcing</h2>
                              <p>
                                We deliver flexible IT outsourcing solution,
                                allowing you to focus on core business while we
                                handle your tech needs.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="owl-item cloned" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner2">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser7.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>Data Analytics</h2>
                              <p>
                                We provide actionable insights through
                                analytics, helping you make informed decisions &
                                drive business growth
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="owl-item cloned" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser8.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>Digital Marketing</h2>
                              <p>
                                We develop tailored marketijng strategies to
                                enhance your brand visibility and engage your
                                target audience
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="owl-item" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner2">
                            <div className="feature-top-icon up">
                              <img
                                src="/images/ser5.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>IT Consulting</h2>
                              <p>
                                We offer IT consulting services to optimize
                                technology strategy & drive effective digital
                                transformation{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="owl-item" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser6.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>IT Outsourcing</h2>
                              <p>
                                We deliver flexible IT outsourcing solution,
                                allowing you to focus on core business while we
                                handle your tech needs.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="owl-item" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner2">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser7.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>Data Analytics</h2>
                              <p>
                                We provide actionable insights through
                                analytics, helping you make informed decisions &
                                drive business growth
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="owl-item active" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser6.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>IT Outsourcing</h2>
                              <p>
                                We deliver flexible IT outsourcing solution,
                                allowing you to focus on core business while we
                                handle your tech needs.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="owl-item cloned active"
                    style={{ width: '285px' }}
                  >
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser8.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>Digital Marketing</h2>
                              <p>
                                We develop tailored marketijng strategies to
                                enhance your brand visibility and engage your
                                target audience
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="owl-item cloned active"
                    style={{ width: '285px' }}
                  >
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner2">
                            <div className="feature-top-icon up">
                              <img
                                src="/images/ser5.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>IT Consulting</h2>
                              <p>
                                We offer IT consulting services to optimize
                                technology strategy & drive effective digital
                                transformation{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="owl-item cloned active"
                    style={{ width: '285px' }}
                  >
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser6.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>IT Outsourcing</h2>
                              <p>
                                We deliver flexible IT outsourcing solution,
                                allowing you to focus on core business while we
                                handle your tech needs.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="owl-item cloned" style={{ width: '285px' }}>
                    <div className="col-lg-12">
                      <div className="feature-extra-single-box">
                        <div
                          className="em-feature-single-box wow fadeInLeft animated"
                          data-wow-delay=".5"
                          style={{
                            visibility: 'visible',
                            animationName: 'fadeInLeft'
                          }}
                        >
                          <div className="feature-single-box-inner2">
                            <div className="feature-top-icon">
                              <img
                                src="/images/ser7.png"
                                className="img-fluid w-50"
                                alt=""
                              />
                            </div>
                            <div className="em-feature-title">
                              <h2>Data Analytics</h2>
                              <p>
                                We provide actionable insights through
                                analytics, helping you make informed decisions &
                                drive business growth
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="container py-5">
            <div className="row">
              {features.map((feature, index) => (
                <div key={index} className="col-lg-3 col-md-6 mb-4">
                  <div className="feature-extra-single-box">
                    <div
                      className="em-feature-single-box wow fadeInLeft animated"
                      data-wow-delay=".5"
                      style={{
                        visibility: 'visible',
                        animationName: 'fadeInLeft'
                      }}
                    >
                      <div className="feature-single-box-inner">
                        <div className="feature-top-icon">
                          <img
                            src={feature.image}
                            className="img-fluid w-50"
                            alt={feature.title}
                          />
                        </div>
                        <div className="em-feature-title">
                          <h2>{feature.title}</h2>
                          <p>{feature.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
