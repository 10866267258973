import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';

const Partner = () => {
  return (
    <>
      <section id="section-quote">
        <div className="col-lg-12">
          <div className="section_title text_center  ">
            <div className="section_sub_title uppercase mb-3">
              {/* <h6>TESTIMONIAL</h6> */}
            </div>
            <div className="section_main_title">
              <h1>
                Our Happy <span>Clients Says</span>
              </h1>
            </div>
            <div className="em_bar">
              <div className="em_bar_bg"></div>
            </div>
          </div>
        </div>

        <div className="container-pe-quote left mt-80">
          <div className="pp-quote li-quote-1" data-textquote="quote-text-1">
            <div className="img animated bounce"></div>
          </div>
          <div className="pp-quote li-quote-2" data-textquote="quote-text-2">
            <div className="img animated bounce"></div>
          </div>
          <div className="pp-quote li-quote-3" data-textquote="quote-text-3">
            <div className="img animated bounce"></div>
          </div>
          <div className="pp-quote li-quote-4" data-textquote="quote-text-4">
            <div className="img animated bounce"></div>
          </div>
          <div className="pp-quote li-quote-5" data-textquote="quote-text-5">
            <div className="img animated bounce"></div>
          </div>
          <div className="pp-quote li-quote-6" data-textquote="quote-text-6">
            <div className="img animated bounce"></div>
          </div>
        </div>

        <div className="container-pe-quote right mt-80">
          <div className="pp-quote li-quote-7" data-textquote="quote-text-7">
            <div className="img animated bounce"></div>
          </div>
          <div className="pp-quote li-quote-8" data-textquote="quote-text-8">
            <div className="img animated bounce"></div>
          </div>
          <div className="pp-quote li-quote-9" data-textquote="quote-text-9">
            <div className="img animated bounce"></div>
          </div>
          <div className="pp-quote li-quote-10" data-textquote="quote-text-10">
            <div className="img animated bounce"></div>
          </div>
          <div className="pp-quote li-quote-11" data-textquote="quote-text-11">
            <div className="img animated bounce"></div>
          </div>
          <div className="pp-quote li-quote-13" data-textquote="quote-text-13">
            <div className="img animated bounce"></div>
          </div>
        </div>

        <Swiper
          className="mySwiper"
          spaceBetween={30}
          slidesPerView={1}
          loop={true}
          autoplay={true}
          pagination={{
            clickable: true,
            bulletClass: 'swiper-pagination-bullet',
            bulletActiveClass: 'swiper-pagination-bullet-active'
          }}
          modules={[Autoplay, Pagination]}
          style={{
            '--swiper-pagination-color': '#FFBA08',
            '--swiper-pagination-bullet-inactive-color': '#999999',
            '--swiper-pagination-bullet-inactive-opacity': '1',
            '--swiper-pagination-bullet-size': '8px',
            '--swiper-pagination-bullet-horizontal-gap': '6px'
          }}
        >
          <SwiperSlide className="mb-80">
            <div className="container">
              <p className="p-margin">
                Working with Amizhth was a seamless experience. They quickly
                understood our requirements and made the development process
                smooth and efficient. The quality of the final web portal
                exceeded our expectations, delivered within a tight timeline.
              </p>
              <div className="img-con">
                <div className="test-img">
                  <img
                    src="/images/test2.jpg"
                    className="test-image"
                    alt="Dinesh K Sundaravelu"
                  />
                </div>
                <div className="name">
                  <h5>
                    Dinesh K Sundaravelu <br /> VP, StartupTN
                  </h5>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="container">
              <p className="p-margin">
                Amizhth delivered our web portal in record time without
                compromising on quality. Their attention to detail,
                responsiveness, and commitment to excellence truly impressed us.
                We couldn&apos;t have asked for a better partner for our
                project!
              </p>
              <div className="img-con">
                <div className="test-img">
                  <img
                    src="/images/test3.jpg"
                    className="test-image"
                    alt="Nikunj Panchal"
                  />
                </div>
                <div className="name">
                  <h5>
                    Nikunj Panchal <br /> AVP, StartupTN
                  </h5>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="container">
              <p className="p-margin">
                Amizhth has been instrumental in boosting our sales on Amazon
                and Flipkart. Their seller management services have streamlined
                our operations, optimized our listings, and significantly
                increased our visibility. We&apos;ve seen consistent growth,
                thanks to their expertise and dedication.
              </p>
              <div className="img-con">
                <div className="test-img">
                  <img
                    src="/images/suguna.JPG"
                    className="test-image"
                    alt="Suguna"
                  />
                </div>
                <div className="name">
                  <h5>
                    Suguna <br /> Sai Empowerment
                  </h5>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="container">
              <p className="p-margin">
                Kanimai has completely transformed how we manage our education
                institute. From admissions to attendance and fee management,
                everything is seamless and efficient. The app is user-friendly,
                and Amizhth Techno Solutions provided excellent support
                throughout the process. It&apos;s been a game-changer for us!
              </p>
              <div className="img-con">
                <div className="test-img">
                  <img
                    src="/images/test8.jpg"
                    className="test-image"
                    alt="Ramachandran"
                  />
                </div>
                <div className="name">
                  <h5>
                    Ramachandran <br /> Director, Ramanas Arts & Science College
                    for Women
                  </h5>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="container">
              <p className="p-margin">
                Partnering with Amizhth Techno Solutions for social media
                marketing has transformed my brand’s visibility. Their
                innovative strategies not only expanded my reach but also drove
                a notable increase in sales. Their commitment to understanding
                my business and delivering impactful results has exceeded my
                expectations.
              </p>
              <div className="img-con">
                <div className="test-img">
                  <img
                    src="/images/test1.jpg"
                    className="test-image"
                    alt="KATHIRAVAN M"
                  />
                </div>
                <div className="name">
                  <h5>
                    KATHIRAVAN M <br /> Director Seyon Design International
                  </h5>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <img src="/images/desert.png" className="img-fluid mt-20" />
      </section>
    </>
  );
};

export default Partner;
