import React from 'react';
// import { Box } from '@mui/material';
// import { Swiper, SwiperSlide } from 'swiper/react';

const Blog = () => {
  return (
    <>
      <div className="blog_area pt-10 pb-65">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section_title style-two mb-50 mt-3 wow fadeInDown"
                data-wow-delay=".3"
              >
                <div className="section_sub_title">
                  <h5>Our Blog</h5>
                </div>
                <div className="section_main_title">
                  <h1>Latest from Our Blog</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single_blog mb-30">
                <div className="single_blog_thumb pb-4">
                  <a href="/blog/cloud-service">
                    <img src="/images/blog-pic1.png" alt="" />
                  </a>
                </div>
                <div className="single_blog_content pl-4 pr-4">
                  <div className="techno_blog_meta">
                    <a href="/blog/cloud-service">Amizhth</a>
                    <span className="meta-date pl-3">
                      March 24, 2024 | By Pradeepa
                    </span>
                  </div>
                  <div className="blog_page_title pb-35">
                    <h3>
                      <a href="/blog/cloud-service">
                        Modernise Applications by Exploiting the Cloud <br />{' '}
                        Services.
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single_blog mb-30">
                <div className="single_blog_thumb pb-4">
                  <a href="/blog/springboot-tomcat">
                    <img src="/images/blog-pic2.png" alt="" />
                  </a>
                </div>
                <div className="single_blog_content pl-4 pr-4">
                  <div className="techno_blog_meta">
                    <a href="/blog/springboot-tomcat">Amizhth</a>
                    <span className="meta-date pl-3">
                      June 3, 2024 | By Antony Basco
                    </span>
                  </div>
                  <div className="blog_page_title pb-35">
                    <h3>
                      <a href="/blog/springboot-tomcat">
                        Spring Boot Application on AWS EC2 using Apache Tomcat
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="single_blog mb-30">
                <div className="single_blog_thumb pb-4">
                  <a href="/blog/springboot-docker">
                    <img src="/images/blog-pic3.png" alt="" />
                  </a>
                </div>
                <div className="single_blog_content pl-4 pr-4">
                  <div className="techno_blog_meta">
                    <a href="/blog/springboot-docker">Amizhth</a>
                    <span className="meta-date pl-3">
                      August 4, 2024 | By Antony Basco{' '}
                    </span>
                  </div>
                  <div className="blog_page_title pb-35">
                    <h3>
                      <a href="/blog/springboot-docker">
                        Spring Boot Application as Docker Container Image on AWS
                        EC2
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
