import React from 'react';

const Footer = () => {
  return (
    <>
      <div
        className="footer-middle pt-95"
        style={{ backgroundImage: 'url(/images/call-bg.png)' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="widget widgets-company-info">
                <div className="footer-bottom-logo pb-40">
                  <img
                    src="/images/logo.png"
                    className="img-fluid w-50"
                    alt=""
                  />
                </div>
                <div className="company-info-desc">
                  <p>
                    Amizhth Techno Solutions blends innovation and expertise to
                    deliver customized software solutions, specializing in web,
                    mobile, and cybersecurity services, with a commitment to
                    client success.
                  </p>
                </div>
                <div className="follow-company-info pt-3">
                  <div className="follow-company-text mr-3">
                    <a href="#">
                      <p>Follow Us</p>
                    </a>
                  </div>
                  <div className="follow-company-icon">
                    <a href="https://www.facebook.com/Amizhth">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="https://www.instagram.com/amizhth/">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="https://in.linkedin.com/company/amizhth">
                      <i className="bi bi-linkedin"></i>
                    </a>
                    <a href="#">
                      <i className="bi bi-youtube"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="widget widget-nav-menu">
                <h4 className="widget-title pb-4">Our Services</h4>
                <div className="menu-quick-link-container ml-4">
                  <ul id="menu-quick-link" className="menu">
                    <li>
                      <a href="/service">App Development</a>
                    </li>
                    <li>
                      <a href="/service">Web Development</a>
                    </li>
                    <li>
                      <a href="/service">IT Outsourcing</a>
                    </li>
                    <li>
                      <a href="/service">Cyber Security</a>
                    </li>
                    <li>
                      <a href="/service">Digital Marketing</a>
                    </li>
                    <li>
                      <a href="/service">UI/UX Design </a>
                    </li>
                    <li>
                      <a href="/service">Cloud Managed Services</a>
                    </li>
                    <li>
                      <a href="/service">Data Analytics</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="widget widgets-company-info">
                <h3 className="widget-title pb-4">Company Address</h3>
                <div className="footer-social-info">
                  <p>
                    <span>Address :</span>153 / P, North Velli Street,
                    Simmakkal, Madurai - 625009
                  </p>
                </div>
                <div className="footer-social-info">
                  <p>
                    <span>Address :</span>2/397, Perumbakkam Main Rd,
                    Perumbakkam, Chennai - 600100
                  </p>
                </div>
                <div className="footer-social-info">
                  <p>
                    <span>Email :</span>vanakkam@amizhth.com
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div id="em-recent-post-widget">
                <div className="single-widget-item">
                  <h4 className="widget-title pb-3">Recent Blog</h4>
                  <div className="recent-post-item active pb-3">
                    <div className="recent-post-image mr-3">
                      <a href="/blog/cloud-service">
                        <img
                          width="80"
                          height="80"
                          src="/images/blog-pic1.png"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="recent-post-text">
                      <h6>
                        <a href="/blog/cloud-service">
                          Modernise Applications by Exploiting the Cloud
                          Services.
                        </a>
                      </h6>
                      <span className="rcomment">March 24, 2024</span>
                    </div>
                  </div>
                  <div className="recent-post-item pt-1">
                    <div className="recent-post-image mr-3">
                      <a href="/blog/springboot-tomcat">
                        <img
                          width="80"
                          height="80"
                          src="/images/blog-pic2.png"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="recent-post-text">
                      <h6>
                        <a href="/blog/springboot-tomcat">
                          Spring Boot Application on AWS EC2 using Apache Tomcat
                        </a>
                      </h6>
                      <span className="rcomment">June 3, 2024</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row footer-bottom mt-70 pt-3 pb-1">
            <div className="col-lg-6 col-md-6">
              <div className="footer-bottom-content">
                <div className="footer-bottom-content-copy">
                  <p>
                    Powered By{' '}
                    <span>
                      <img src="/fav.png" className="footer-logo" />
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="footer-bottom-right">
                <div className="footer-bottom-right-text">
                  <a className="absod" href="/privacypolicy">
                    Privacy Policy
                  </a>
                  <a href="/termsandconditions"> Terms & Conditions</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
